import { DocumentNode } from "graphql";
import browserLogger from '../../../settings/browser-logger';
import { FetchPolicy } from "@apollo/client";
import client from "./client";

export interface QueryResult {
    success: boolean;
    errorMessage: string | null;
    data: any;
}

export const dtoneQuery = (
    query: DocumentNode,
    variables: Record<string, any> = {},
    fetchPolicy: FetchPolicy = 'network-only'
): Promise<QueryResult> => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .query({
                variables,
                query,
                fetchPolicy
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                browserLogger.error(
                    err.message,
                    {
                        error: err,
                        function: 'graphqlQuery',
                    },
                );
                //log to Sentry
                console.log({
                    error: err,
                    query: query && query.definitions && query.definitions.length && query.definitions[0]['name'] && query.definitions[0]['name'].value
                })
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: err.message };
                resolve(objToBeReturned);
            });
    });
};